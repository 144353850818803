<template>
  <div>
    <v-row align="end" justify="space-between" no-gutters>
      <v-col cols="auto">
        <GoBack />
        <p class="header-text">
          {{
            assignmentData.assignment.title
              | formatAssignmentTitle(assignmentData.assignment.short_title)
          }}
        </p>
        <p class="lightCarbon--text font-weight-regular caption">
          {{ assignmentData.assignment.courses | formatCourses }}
        </p>
      </v-col>
      <v-col :class="{ 'mt-3': $vuetify.breakpoint.mdAndDown }" cols="auto">
        <v-btn
          class="px-14 button-start-checking"
          color="primary"
          depressed
          min-height="55"
          min-width="60"
          :to="{
            name: 'StartChecking',
            query: {
              assignmentId,
            },
          }"
        >
          Start Checking
        </v-btn>
        <p class="text-center text--secondary font-weight-regular caption">
          Start checking this assignment
        </p>
      </v-col>
    </v-row>

    <v-divider class="mt-2 mb-4" />

    <v-row class="my-6 mb-16" dense>
      <v-col
        v-for="(item, index) in cards"
        :key="index"
        cols="12"
        lg="3"
        md="6"
      >
        <AnalyticsCard
          :body="item.body"
          :color="item.color"
          :title="item.title"
        />
      </v-col>
    </v-row>

    <div class="my-4">
      <ViewAssignmentSearch
        :get-assignments-data="getAssignmentsData"
        :reset="resetPageNo"
      />
    </div>

    <v-data-table
      :custom-sort="() => viewAssignments.data.data"
      :headers="headers"
      hide-default-footer
      :items="viewAssignments.data.data"
      :loading="listLoading"
      multi-sort
      :sort-by="viewAssignments.sort.fields"
      :sort-desc="viewAssignments.sort.desc"
      @update:options="handleSort"
    >
      <template v-slot:[`header.action`]>
        <span class="subtitle-2 lightCarbon--text">
          {{ totalEntries }}
        </span>
      </template>

      <template v-slot:[`item.user.full_name`]="{ item }">
        <UserNameAndAvatar
          :full-name="item.user.full_name"
          :url="item.user.photo"
        />
      </template>

      <template v-slot:[`item.submitted_at`]="{ item }">
        {{ item.submitted_at ? moment(item.submitted_at).format("ll") : "-" }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <span v-if="item.review?.result === 'passed'" class="green--text">
          Passed
        </span>
        <span v-else-if="item.review?.result === 'failed'" class="red--text">
          Failed
        </span>
        <span v-else-if="!item.status" class="red--text">
          Not Submitted
        </span>
        <span v-else-if="item.status === 'plagiarised'" class="red--text">
          Plagiarised
        </span>
        <span
          v-else-if="
            item.status === 'not_reviewed' || item.status === 'under_review'
          "
          class="orange--text"
        >
          Pending
          <v-icon
            v-if="item.order > 1"
            class="re-submission-icon"
            color="white"
            size="20"
          >
            mdi-refresh
          </v-icon>
        </span>
        <span v-else-if="item.status === 'accepted'" class="green--text">
          Accepted
        </span>
        <span v-else-if="item.status === 'rejected'" class="red--text">
          Rejected
        </span>
      </template>

      <template v-slot:[`item.review`]="{ item }">
        <span>
          {{ item.review ? item.review.professor : "-" }}
        </span>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          class="pr-0 pr-md-2"
          color="primary"
          small
          text
          :to="{
            name: 'ViewSubmission',
            params: { submissionId: item.uuid },
          }"
        >
          VIEW SUBMISSION
        </v-btn>
      </template>
      <template v-slot:footer>
        <Paginator
          :page="viewAssignments.page"
          :total="viewAssignments.data.total"
          @change="handlePagination"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ViewAssignmentSearch from "@/components/dashboard/assignments/ViewAssignmentSearch.vue"
import Paginator from "@/components/shared/Paginator.vue"
import AnalyticsCard from "@/components/shared/AnalyticsCard.vue"
import GoBack from "@/components/shared/GoBack.vue"
import UserNameAndAvatar from "@/components/shared/UserNameAndAvatar.vue"
import urls from "@/utils/urls"
import { getValueOrNull } from "@/utils/helpers"
import { mapGetters, mapActions } from "vuex"
import filters from "@/utils/mixins/filters"

export default {
  name: "ViewAssignment",

  components: {
    ViewAssignmentSearch,
    Paginator,
    AnalyticsCard,
    GoBack,
    UserNameAndAvatar,
  },

  mixins: [filters],

  data: () => ({
    assignmentId: null,
    headers: [
      {
        text: "Name",
        value: "user.full_name",
        sortable: false,
      },
      { text: "Email address", value: "user.email", sortable: false },
      // { text: "Batch", value: "batch_name", sortable: false },
      { text: "Submissions", value: "order", sortable: false },
      {
        text: "Date",
        value: "submitted_at",
        sortable: false,
      },
      { text: "Reviewer", value: "review", sortable: false },
      { text: "Status", value: "status", sortable: false },
      {
        text: "",
        value: "action",
        sortable: false,
        align: "end",
      },
    ],
    assignmentData: {
      accepted_submissions: 0,
      total_submissions: 0,
      allocated_users: 0,
      pending_reviews: 0,
      assignment: {
        title: "Assignment",
        courses: [],
      },
    },
  }),

  computed: {
    ...mapGetters(["viewAssignments", "listLoading"]),

    totalEntries() {
      const { data, page } = this.viewAssignments

      return `${page * 10 > data.total ? data.total : page * 10} of ${
        data.total
      } Entries`
    },
    cards() {
      const {
        allocated_users,
        total_submissions,
        pending_reviews,
        accepted_submissions,
      } = this.assignmentData
      return [
        {
          title: "Total Allocated Users",
          color: "purple",
          body: allocated_users,
        },
        {
          title: "Total Submissions",
          color: "yellow",
          body: total_submissions,
        },
        {
          title: "Accepted Submissions",
          color: "green",
          body: accepted_submissions,
        },
        {
          title: "Pending Reviews",
          color: "red",
          body: pending_reviews,
        },
      ]
    },
  },

  beforeMount: function() {
    this.assignmentId = this.$route.params.assignmentId
  },

  mounted: function() {
    this.getAssignmentsData()
  },

  methods: {
    ...mapActions(["getViewAssignments"]),

    async getAssignmentsData() {
      try {
        const res = await this.$http.get(
          urls.assignments.overview(this.assignmentId),
          {
            params: {
              batch: getValueOrNull(this.viewAssignments.selectedBatch),
              status: getValueOrNull(this.viewAssignments.selectedStatus),
            },
          }
        )
        this.assignmentData = res.data.data
      } catch (error) {
        console.error(error)
      }
    },
    resetPageNo() {
      this.$store.commit("SET_VIEW_ASSIGNMENT_PAGE", { page: 1 })
    },

    handleSort({ sortBy, sortDesc }) {
      this.resetPageNo()
      this.$store.commit("SET_VIEW_ASSIGNMENT_SORTABLE_FIELDS", {
        fields: sortBy,
        desc: sortDesc,
      })
      this.getViewAssignments(this.assignmentId)
    },

    handlePagination(newPage) {
      this.$store.commit("SET_VIEW_ASSIGNMENT_PAGE", { page: newPage })
      this.getViewAssignments(this.assignmentId)
    },
  },
}
</script>
<style lang="scss" scoped>
.re-submission-icon {
  position: relative;
  background: #3b82f6;
  border-radius: 4px;
  color: white;
  margin-left: 8px;
  padding: 4px;
}
</style>
